var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _vm.error
            ? _c("v-col", { attrs: { cols: "12" } }, [_c("dashboard-error")], 1)
            : _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.csvExporting,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.csvExport()
                            },
                          },
                        },
                        [_vm._v("Export to CSV")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "offset-y": "",
                            "close-on-content-click": false,
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { staticClass: "mr-2" },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(" Columns "),
                                      _c("v-icon", { attrs: { right: "" } }, [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: { multiple: "" },
                                  model: {
                                    value: _vm.selectedHeaders,
                                    callback: function ($$v) {
                                      _vm.selectedHeaders = $$v
                                    },
                                    expression: "selectedHeaders",
                                  },
                                },
                                _vm._l(_vm.orderedHeaders, function (column) {
                                  return _c("v-list-item", {
                                    key: column.column_id,
                                    attrs: { value: column },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({ active }) {
                                            return [
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value": active,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  _c("v-list-item-title", [
                                                    _vm._v(_vm._s(column.text)),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-autocomplete", {
                        attrs: {
                          dense: "",
                          solo: "",
                          multiple: "",
                          "hide-details": "",
                          chips: "",
                          clearable: "",
                          "deletable-chips": "",
                          "return-object": "",
                          "item-text": "name",
                          items: _vm.filters,
                          label: "Filters",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item, on, attrs }) {
                              return [
                                _c(
                                  "v-list-item",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: { "two-line": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({ active }) {
                                                return [
                                                  _c(
                                                    "v-list-item-action",
                                                    [
                                                      _c("v-checkbox", {
                                                        attrs: {
                                                          "input-value": active,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-list-item-content",
                                                    [
                                                      _c("v-list-item-title", [
                                                        _vm._v(
                                                          _vm._s(item.name)
                                                        ),
                                                      ]),
                                                      _c(
                                                        "v-list-item-subtitle",
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.description
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      "v-list-item",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.selectedFilters,
                          callback: function ($$v) {
                            _vm.selectedFilters = $$v
                          },
                          expression: "selectedFilters",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    staticClass: "inventory-table",
                    attrs: {
                      headers: _vm.filteredHeaders,
                      items: _vm.inventory,
                      "item-key": _vm.oid ? "sid" : "oid",
                      options: _vm.options,
                      "server-items-length": _vm.inventorySize,
                      "footer-props": {
                        "items-per-page-options": _vm.itemsPerPageOptions,
                      },
                      loading:
                        _vm.fetchingInitInventory ||
                        _vm.fetchingInventory ||
                        _vm.fetchingInventorySize,
                      expanded: _vm.expanded,
                      "show-expand": "",
                    },
                    on: {
                      "update:options": function ($event) {
                        _vm.options = $event
                      },
                      "update:expanded": function ($event) {
                        _vm.expanded = $event
                      },
                      "click:row": _vm.clickRow,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "top",
                          fn: function ({
                            options,
                            pagination,
                            updateOptions,
                          }) {
                            return [
                              _c("v-data-footer", {
                                staticClass: "no-border",
                                attrs: {
                                  pagination: pagination,
                                  options: options,
                                  itemsPerPageOptions: _vm.itemsPerPageOptions,
                                  "items-per-page-text":
                                    "$vuetify.dataTable.itemsPerPageText",
                                },
                                on: { "update:options": updateOptions },
                              }),
                            ]
                          },
                        },
                        {
                          key: "expanded-item",
                          fn: function ({ headers }) {
                            return [
                              _c(
                                "td",
                                { attrs: { colspan: headers.length } },
                                [
                                  _vm.oid
                                    ? [
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          color:
                                                            "grey lighten-3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "text-body-1",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                "Space admins"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          [
                                                            _vm.fetchingOrgLevelData
                                                              ? _c(
                                                                  "dashboard-loader"
                                                                )
                                                              : [
                                                                  _vm
                                                                    .spaceAdmins
                                                                    .length >
                                                                    0 &&
                                                                  !_vm.$isError(
                                                                    _vm.spaceAdmins
                                                                  )
                                                                    ? _vm._l(
                                                                        _vm.spaceAdmins,
                                                                        function (
                                                                          admin,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i,
                                                                              staticClass:
                                                                                "py-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    admin.full_name
                                                                                  ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        admin.email
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "copy-to-clipboard",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      textToCopy:
                                                                                        admin.email,
                                                                                      iconButton: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .spaceAdmins
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        "dashboard-empty"
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.$isError(
                                                                    _vm.spaceAdmins
                                                                  )
                                                                    ? _c(
                                                                        "dashboard-error"
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    : [
                                        _c(
                                          "v-container",
                                          { attrs: { fluid: "" } },
                                          [
                                            _c(
                                              "v-row",
                                              [
                                                _c(
                                                  "v-col",
                                                  [
                                                    _c(
                                                      "v-card",
                                                      {
                                                        attrs: {
                                                          flat: "",
                                                          color:
                                                            "grey lighten-3",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-card-title",
                                                          {
                                                            staticClass:
                                                              "text-body-1",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                "Managers"
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                        _c(
                                                          "v-card-text",
                                                          [
                                                            _vm.fetchingRPLevelData
                                                              ? _c(
                                                                  "dashboard-loader"
                                                                )
                                                              : [
                                                                  _vm
                                                                    .orgManagers
                                                                    .length >
                                                                    0 &&
                                                                  !_vm.$isError(
                                                                    _vm.orgManagers
                                                                  )
                                                                    ? _vm._l(
                                                                        _vm.orgManagers,
                                                                        function (
                                                                          manager,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i,
                                                                              staticClass:
                                                                                "py-1",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    manager.full_name
                                                                                  ) +
                                                                                  ": "
                                                                              ),
                                                                              _c(
                                                                                "v-chip",
                                                                                {
                                                                                  staticClass:
                                                                                    "mx-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " " +
                                                                                      _vm._s(
                                                                                        manager.email
                                                                                      ) +
                                                                                      " "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "copy-to-clipboard",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      textToCopy:
                                                                                        manager.email,
                                                                                      iconButton: true,
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .orgManagers
                                                                    .length ===
                                                                  0
                                                                    ? _c(
                                                                        "dashboard-empty"
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm.$isError(
                                                                    _vm.orgManagers
                                                                  )
                                                                    ? _c(
                                                                        "dashboard-error"
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                ],
                                2
                              ),
                            ]
                          },
                        },
                        {
                          key: `item.actions`,
                          fn: function ({ item }) {
                            return [
                              item.space_admins === 0
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        loading: _vm.deletingSpace === item.sid,
                                      },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          return _vm.deleteSpace(item.sid)
                                        },
                                      },
                                    },
                                    [
                                      _c("v-icon", { attrs: { small: "" } }, [
                                        _vm._v("delete"),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }